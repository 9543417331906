<template>
  <q-page padding>
    <CouponGenerator />
  </q-page>
</template>

<script>
import CouponGenerator from '../../components/api/CouponGenerator';

export default {
  components: { CouponGenerator },
  name: 'Coupon'
};
</script>

<style scoped></style>
