<template>
  <q-card flat class="justify-center creative">
    <q-card-section>
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">API Cupom</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="API Cupom" exact />
      </q-breadcrumbs>
    </q-card-section>
    <q-separator spaced />

    <q-card-section class="row q-col-gutter-md">
      <div class="col-6">
        <q-input outlined readonly type="text" v-model="affiliateData.id" label="Afiliado ID">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.id)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Copiar ID </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>

      <div class="col-6">
        <q-input outlined readonly type="text" v-model="affiliateData.token" label="Token">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.token)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Copiar Token
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>

      <div class="col-6">
        <select-request
          :v-model="channel"
          outlined
          :endpoint="`redirect?app=AFFILIATE&path=/api/get/affiliate/site_active/list/${getProfiles.affiliate_id}`"
          option-label="name"
          option-value="id"
          label="Selecione o canal"
          clearable
          @update="(value) => (channel = value === null ? null : value.id)"
        />
      </div>

      <div class="col-6">
        <q-input outlined readonly type="text" v-model="channelID" label="Canal ID"> </q-input>
      </div>

      <div class="col-6">
        <q-select
          v-model="display"
          :options="optionsDisplay"
          label="Modo de exibição"
          :rules="[(val) => !!val || 'O formato é obrigatório.']"
          outlined
        />
      </div>

      <div class="col-12">
        <q-input outlined readonly type="text" :value="getApiLink" label="URL API de Cupons">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(getApiLink)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Copiar API
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
const dataLink = process.env.VUE_APP_DATA_API;

import affiliateService from '../../services/AffiliateService';
import AffiliateMixin from '../../mixins/AffiliateMixin';
import SelectRequest from '../../widgets/inputsGlobal/SelectRequest';

export default {
  components: { SelectRequest },
  mixins: [affiliateService, AffiliateMixin],
  name: 'CouponGenerator',
  data() {
    return {
      optionsDisplay: ['CSV', 'JSON'],
      affiliateData: {
        email: null,
        id: null,
        token: null
      },
      apiDataUrl: dataLink,
      channel: null,
      channelID: null,
      display: null,
      login: null
    };
  },
  watch: {
    channel() {
      this.selectChannel(this.channel);
    }
  },
  computed: {
    getProfiles: {
      get() {
        return this.$store.state.Account.user;
      }
    },
    getApiLink: {
      get() {
        if (this.channelID && this.display) {
          return `${this.apiDataUrl}/feedcupoms?token=${this.affiliateData.token}&mode=${this.display}&canalid=${this.channelID}&affid=${this.affiliateData.id}`;
        }

        return null;
      }
    }
  },
  methods: {
    selectChannel(value) {
      console.log(value);
      this.channelID = value;
    },

    // copyData(value) {
    //     this.copyToClipboard(value)
    //         .then(() => {
    //             this.successNotify("Copiado!");
    //         })
    //         .catch(() => {
    //             this.errorNotify("Erro ao copiar");
    //         });
    // },

    async setupAffiliateData() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateData(this.getProfiles.affiliate_id);
        if (status === 200) {
          this.affiliateData = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  created() {
    this.setupAffiliateData();
  }
};
</script>

<style scoped></style>
